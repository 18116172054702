import { format, parseISO } from 'date-fns';
import { fi } from 'date-fns/locale';

const parseDate = (date) => (date instanceof Date) ? date : parseISO(date);

export const getLocale = (locale='fi') => {
    return fi;
}

export const dateFormat = (date) => {
    if(!date) return;
    return format(parseDate(date),'dd.MM.yyyy');
}

export const dateFormatShort = (date,locale) => {
    if(!date) return;
    return format(parseDate(date),'eeeeee dd.MM.',{ locale: getLocale(locale) });
}


export const timeFormat = (date) => {
    if(!date) return;
    return format(parseDate(date),'H.mm');
}