import React from 'react';
import Loading from 'react-fullscreen-loading';

class LoadingFullScreen extends React.Component {
  render() {
    return(
      <Loading loading background="#aaa" loaderColor="#a91512" />
    )
  }
}

export default LoadingFullScreen;