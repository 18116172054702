const HtmlContent = ({t,ident,replaces}) => {
    let content = t(ident,{ 'interpolation': {'escapeValue': false}});
    if(replaces) {
        replaces.forEach(item => {
            content = content.replace(item[0],item[1]);
        });
    }
    return (
        <p 
        dangerouslySetInnerHTML={{
            __html: content
        }} />
      )
    };
export default HtmlContent;