import React from 'react';
import { 
    Button, 
    Col, 
    Container, 
    Form, 
    FormLabel, 
    InputGroup, 
    Row, 
    Alert,
} from 'react-bootstrap';
import LoadingFullScreen from '../components/LoadingFullScreen';
import axios from 'axios';

class Enquiry extends React.Component {
    state = {
        initialized: false,
        isSaving: false,
        enquiryData: null,
        newKilometers: "",
        illogicalKilometers: false,
        usageSaved: false,
        alreadyResponded: false,
    }
    constructor(props) {
        super(props);
        this.t = props.t;
        this.apiurl = process.env.REACT_APP_API_URL;
    }
    componentDidMount() {
        if(this.props.enquiryId && this.props.enquiryHash) {
            // authorize enquiry
            axios({
                method: 'GET',
                url: this.apiurl + '/vehicle-usage/enquiries',
                params: {
                    enquiryId: this.props.enquiryId,
                    enquiryHash: this.props.enquiryHash,
                }
            })
            .then(res => {
                if(res.data.enquiryData.responsetime) {
                    this.setState({
                        initialized: true,
                        alreadyResponded: true,
                    });
                }
                else {
                    this.setState({
                        initialized: true,
                        enquiryData: res.data.enquiryData,
                        leasedData: res.data.leasedData,
                    });
                }
            })
            .catch(err => {
                this.setState({
                    initialized: true,
                    enquiryData: null,
                    leasedData: null,
                });
                console.error("Getting enquiry failed",err);
            })
        }
    }
    newKilometersChange = (ev) => {
        const km = ev.target.value*1;
        this.setState({
            newKilometers: km,
        });
        this.setState(prevState => ({
            illogicalKilometers: (prevState.illogicalKilometers && !this.newKilometersUnderCurrent(km)) ? false : prevState.illogicalKilometers,
        }));
    }
    newKilometersUnderCurrent = (km) => {
        return this.state.leasedData.latestUsage && km<this.state.leasedData.latestUsage.Usage;
    }
    handleSubmit = () => {
        if(this.newKilometersUnderCurrent(this.state.newKilometers)) {
            this.setState({
                illogicalKilometers: true,
            });
            return;
        }
        this.setState({
            isSaving: true
        });
        axios({
            method: 'PUT',
            url: this.apiurl + '/vehicle-usage/enquiries/updateUsage',
            data: {
                enquiryId: this.props.enquiryId,
                enquiryHash: this.props.enquiryHash,
                newKilometers: this.state.newKilometers*1,
            }

        })
        .then(res => {
            if(res.data.success) {
                this.setState({
                    isSaving: false,
                    illogicalKilometers: false,
                    usageSaved: true,
                })
            }
        })
        .catch(err => {
            this.setState({
                isSaving: false,
                saveFailed: true,
            });
            console.error("Saving new usage failed",err);
        });
    }
    render() {
        return (
            <>
                {
                    this.state.initialized ? '' : <LoadingFullScreen />
                }
                {
                    (this.state.initialized) ? (
                        <Container>
                            {
                                this.state.enquiryData ? (
                                    <>
                                    <Row>
                                        <Col className="mt-4">
                                            <p>{this.t('GUIDANCE.USAGE_UPDATE')}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div>
                                                <strong>{this.t('VEHICLE')}: </strong>
                                                {
                                                    [
                                                        this.state.leasedData.VehicleMake,
                                                        ' ',
                                                        this.state.leasedData.VehicleModel,
                                                        ' (', this.state.leasedData.LicensePlateNumber + ')'
                                                    ]
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.usageSaved ? (
                                            <Row>
                                                <Col className="mt-3"l>
                                                    <Alert variant="success">
                                                        {this.t('USAGE.SAVED')}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Form>
                                                <Row className="mt-3">
                                                    <Col sm="4">
                                                        <FormLabel>{this.t('USAGE.KILOMETERS')}</FormLabel>
                                                        <InputGroup>
                                                            <Form.Control
                                                            type="number"
                                                            maxLength={6}
                                                            min={0}
                                                            onChange={this.newKilometersChange}
                                                            value={this.state.newKilometers}
                                                            readOnly={this.state.isSaving}
                                                            />
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>km</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                {
                                                    this.state.illogicalKilometers ? (
                                                        <Row>
                                                            <Col className="mt-3">
                                                                <Alert variant="warning">
                                                                    {this.t('USAGE.ILLOGICALKM')}
                                                                    <div>&nbsp;</div>
                                                                    <div>
                                                                        {this.t('CUSTOMER_SERVICE')}: 
                                                                        <div><a href={`mailto:${this.t('CUSTOMER_SERVICE_EMAIL')}`}>{this.t('CUSTOMER_SERVICE_EMAIL')}</a></div>
                                                                        <div>{this.t('TEL')} <a href={`tel:${this.t('CUSTOMER_SERVICE_PHONE_LINK')}`}>{this.t('CUSTOMER_SERVICE_PHONE')}</a></div>

                                                                    </div>
                                                                </Alert>
                                                            </Col>
                                                        </Row>
                                                    ) : null
                                                }
                                                {
                                                    this.state.saveFailed ? (
                                                        <Row>
                                                            <Col className="mt-3">
                                                                <Alert variant="danger">
                                                                    {this.t('USAGE.SAVEFAILED')}
                                                                </Alert>
                                                            </Col>
                                                        </Row>
                                                    ) : null
                                                }
                                                <Row>
                                                    <Col className="mt-3">
                                                        <Button 
                                                        variant="primary"
                                                        onClick={this.handleSubmit}
                                                        disabled={this.state.isSaving || this.state.newKilometers===''}
                                                        >{this.t('BUTTON.SAVE')}</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )
                                    }
                                    </>
                                ) : (
                                    <Row>
                                        <Col>
                                        {
                                            this.state.alreadyResponded
                                            ? <Alert variant="warning">{this.t('USAGE.ENQUIRY_ALREADY_RESPONDED')}</Alert>
                                            : <Alert variant="danger">{this.t('USAGE.ENQUIRY_NOT_FOUND')}</Alert>
                                        }
                                        </Col>
                                    </Row>
                                )
                            }
                        </Container>
                    ) : null
                }
            </>
        );
    }
}

export default Enquiry;