import { Translation } from 'react-i18next';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Enquiry from './components/Enquiry';
import StartPage from './components/StartPage';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App(props) {
  let query = useQuery();
  const enquiryId = query.get('enquiry');
  const enquiryHash = query.get('h');
  const sectoAuth = query.get('sectoauth');
  const licensePlateNumber = query.get('licenseplate');
  return (
    <Translation>
      {
        t => (
          typeof(enquiryId)==='string' ? <Enquiry t={t} locale={props.locale} enquiryId={enquiryId} enquiryHash={enquiryHash} />
          : <StartPage t={t} locale={props.locale} sectoAuth={sectoAuth} licensePlateNumber={licensePlateNumber} />
        )
      }
    </Translation>
  );
}

export default function Base() {
  return (
    <Router>
      <App></App>
    </Router>
  );
};
