import React from 'react';
import { 
    Button, 
    Col, 
    Container, 
    Form, 
    FormLabel, 
    InputGroup, 
    Row, 
    Spinner, 
    Alert,
} from 'react-bootstrap';

import { MsalProvider, withMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

import { validateLicensePlateNumber } from '../validation';
import LoadingFullScreen from '../components/LoadingFullScreen';
import axios from 'axios';
import { dateFormat, dateFormatShort, timeFormat } from './formatters';
import { getISODay, formatISO, parseISO, addDays, addHours, addWeeks, format as dfFormat, addMonths } from 'date-fns';
import update from 'immutability-helper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faAngleDoubleLeft, 
    faAngleDoubleRight,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';

import HtmlContent from './HtmlContent';


const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_AUTH_CLIENTID,
        redirectUri: process.env.REACT_APP_MSAL_AUTH_REDIRECT_URI,
        authority: process.env.REACT_APP_MSAL_AUTH_AUTHORITY+process.env.REACT_APP_MSAL_AUTH_POLICY_SIGNIN,
        knownAuthorities: [process.env.REACT_APP_MSAL_AUTH_KNOWN_AUTHORITY],
    }
};

const b2cPolicies = {
    names: {
        signIn: process.env.REACT_APP_MSAL_AUTH_POLICY_SIGNIN,
        forgotPassword: process.env.REACT_APP_MSAL_AUTH_POLICY_RESET_PASSWORD
    }
}


const msalInstance = new PublicClientApplication(msalConfig);

const IsFetchingSpinner = () => (
    <center>
        <Spinner animation="grow" variant="secondary" />
    </center>
);

class StartPageComponent extends React.Component {
    state = {
        apiError: null,
        initialized: false,
        licensePlateNumber: '',
        licensePlateNumberValid: false,
        licensePlateNumberInvalidVisible: false,
        isFetching: false,
        currentPhase: "START",
        verificationCode: null,
        phaseData: {

        },
        servicePoints: [],
        servicePoint: null,
        bookableTimes: {},
        selectedSlot: null,
        bookingPhoneNumber: '',
        bookingComment: '',
        bookingName: '',
        bookingEmail: '',
        calendarOpenWeek: null,
        firstCalendarWeek: null,
        lastCalendarWeek: null,
        isLoggedIn: false,
        accessToken: null,
        accessTokenRequest: null,
        sectoAuth: false,
        iFrameMode: true,
    }
    constructor(props) {
        super(props);
        this.t = props.t;
        this.apiurl = process.env.REACT_APP_API_URL;
    }
    componentDidMount() {
        if(window.self === window.top) {
            this.setState({
                iFrameMode: false
            })
        }
        if(this.props.licensePlateNumber) {
            this.setState({
                licensePlateNumber: this.props.licensePlateNumber
            },() => {
                this.checkLicensePlateNumber();
            });
        }
        const firstCalendarWeek = this.getFirstCalendarWeek();
        this.setState({
            calendarOpenWeek: firstCalendarWeek,
            firstCalendarWeek
        });
        this.hello();
        if(this.props.sectoAuth) {
            this.sectoAuth();
        }
        else {
            msalInstance.handleRedirectPromise()
            .then(res => {
                if(res && res.accessToken) {
                    this.setState({
                        accessToken: res.accessToken
                    });
                    this.startSession(res.accessToken);
                }
            })
            .catch(err => {
                console.error("msal error",err);
            })
            this.acquireToken();
        }
    }
    sectoLogin = () => {
        window.location.href = (`${this.apiurl}/auth/login?ref=${encodeURIComponent(window.location.href)}`)
    }
    sectoAuth = async () => {
        const options = {
            withCredentials: true,
            url: this.apiurl+"/auth/me",
        }
        axios(options)
        .then((data) => {
            this.setState({
                sectoAuth: true
            })
        })
        .catch(error => {
            this.setState({
                sectoAuth: false
            })
            if(error.response !== undefined && error.response.status === 401){
                this.sectoLogin()
            }
            if(error.data){
                console.error(error.data)
                throw(error.data)
            }
            if(error.response && error.response.data){
                console.error(error.response.data)
                throw(error.response.data)
            }
            if(error.request){
                console.error(error.request)
                throw(error.request)
            }
            console.error(error)
        });
    }
    acquireToken = () => {
        try {
            const accounts = msalInstance.getAllAccounts();
            if(accounts.length===0) {
                return;
            }
            const account = accounts[0];
            const accessTokenRequest = {
                scopes: ["openid",process.env.REACT_APP_MSAL_AUTH_SCOPE],
                account: account
            }
            msalInstance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                this.setState({
                    accessToken: accessTokenResponse.accessToken
                });
                this.startSession(accessTokenResponse.accessToken);
            }).catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
                        this.setState({
                            accessToken: accessTokenResponse.accessToken
                        });
                        this.startSession(accessTokenResponse.accessToken);
                    }).catch(function(error) {
                        // Acquire token interactive failure
                        console.error(error);
                        if (error.errorMessage) {
                            if (error.errorMessage.indexOf("AADB2C90118") > -1) {
                                msalInstance.loginPopup(process.env.REACT_APP_MSAL_AUTH_RESET_PASSWORD)
                                    .then(response => this.handlePolicyChange(response));
                            }
                        }
                    });
                }
                console.error(error);
            });
        }
        catch(e) {
            console.error(e);
        }
    }
    handlePolicyChange = (response) => {
        if (response.idTokenClaims['acr'] === b2cPolicies.names.forgotPassword) {
            window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
            this.logout();
        }
    }
    startSession = (accessToken) => {
        try {
            const options = {
                method: 'put',
                withCredentials: true,
                url: this.apiurl+"/vehicle-usage/auth/bearer",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }
            axios(options)
                .then(res => {
                })
                .catch(err => {
                    console.error("auth/bearer err",err);
                });
                
          }catch(error){
            console.error("failed")
          }
    }
    login = () => {
        this.resetSession();
        var loginRequest = {
            scopes: [process.env.REACT_APP_MSAL_AUTH_SCOPE]
        };
     
         msalInstance.loginRedirect(loginRequest)
             .then(res => {
                 // handle response
                 //this.acquireToken();
             })
             .catch(err => {
                 // handle error
                 this.setState({
                     accessToken: null
                 })
             });
    }
    logout = () => {
        try {
            const options = {
                method: 'get',
                withCredentials: true,
                url: this.apiurl+"/auth/logout",
            }
            axios(options)
                .then(res => {
                    this.setState({
                        accessToken: null,
                        requestId: null,
                    })
                })
                .catch(err => {
                    console.error("auth/bearer err",err);
                });
                
          }catch(error){
            console.error("failed")
          }
        msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        });
    }
    isSectoBooker = () => {
        return this.state.sectoAuth===true;
    }
    isBooker = () => {
        return this.state.sectoAuth===true || this.state.accessToken!==null;
    }
    setCSRFToken = (token) => {
        return localStorage.setItem('CSRF-Token',token);
    }
    getCSRFToken = () => {
        return localStorage.getItem('CSRF-Token')
    }
    hello = () => {
        axios({
            url: this.apiurl + '/vehicle-usage/',
            method: 'get',
            withCredentials: true
        })
            .then(res => {
                if(res.data && res.data.time) {
                    if(res.data.csrfToken) {
                        this.setCSRFToken(res.data.csrfToken);
                    }
                    if(res.data.phase) {
                        this.setState({
                            initialized: true,
                            apiError: null,
                            currentPhase: res.data.phase,
                            phaseData: res.data.phaseData,
                            licensePlateNumber: res.data.phaseData.licensePlateNumber
                        },() => {
                            if(res.data.phase === 'VERIFICATION_SUCCESSFUL' || res.data.phase === 'BOOKING_CONFIRMED') {
                                this.getServicePoints();
                            }
                        });
                    }
                    else {
                        this.setState({
                            initialized: true,
                            apiError: null,
                        });
                    }
                }
                else {
                    this.setState({
                        initialized: true,
                        apiError: "API_CONNECTION_ERROR"
                    });
                }
            })
            .catch(err => {
                this.setState({
                    initialized: true,
                    apiError: "API_CONNECTION_ERROR"
                });
            });
    }
    resetSession = () => {
        axios({
            url: this.apiurl+'/vehicle-usage/resetSession',
            method: 'get',
            withCredentials: true
        })
            .then(res => {
                if(res.data && res.data.status === true) {
                    this.setState({
                        accessTokenRequest: null,
                        requestId: null,
                        initialized: true,
                        licensePlateNumber: '',
                        licensePlateNumberValid: false,
                        licensePlateNumberInvalidVisible: false,
                        logicError: null,
                        currentPhase: 'START',
                        phaseData: {},
                        apiError: null,
                        selectedSlot: null,
                        servicePoint: null
                    });
                }
                else {
                    this.setState({
                        initialized: true,
                        apiError: "API_CONNECTION_ERROR"
                    });
                }
            })
            .catch(err => {
                this.setState({
                    initialized: true,
                    apiError: "API_CONNECTION_ERROR"
                });
            });
    }
    licensePlateNumberSubmit = () => {
        this.setState({
            isFetching: true
        });
        const headers = {};
        let url;
        if(this.isSectoBooker()) {
            url = this.apiurl + '/vehicle-usage/booker/contract';
        }
        else if(this.isBooker()) {
            headers["Authorization"] = `Bearer ${this.state.accessToken}`;
            url = this.apiurl + '/vehicle-usage/booker/contract';
        }
        else {
            url = this.apiurl + '/vehicle-usage/contract';
        }
        url += '?licensePlateNumber=' + this.state.licensePlateNumber.toUpperCase();
        axios({
            url,
            method: 'get',
            withCredentials: true,
            headers
        })
            .then(res => {
                if(res.data.phase) {
                    this.setState((prevState) => ({
                        accessTokenRequest: (res.data.access_token ? res.data.access_token : prevState.accessToken),
                        requestId: res.data.requestId,
                        logicError: null,
                        apiError: null,
                        currentPhase: res.data.phase,
                        phaseData: res.data.phaseData,
                        isFetching: false
                    }));
                    if(res.data.phase==="VERIFICATION_SUCCESSFUL") {
                        this.getServicePoints();
                    }
                }
            })
            .catch(err => {
                console.error(err);
                if(err.response && err.response.status === 404) {
                    this.setState({
                        logicError: "ERROR.CONTRACT_NOT_FOUND",
                        isFetching: false
                    });
                }
                else if(err.response && err.response.status === 401 && this.isBooker()) {
                    this.acquireToken();
                }
                else {
                    this.setState({
                        apiError: "API_REQUEST_FAILED",
                        isFetching: false
                    });
                }
            });
    }
    emailSubmit = (personId) => {
        this.setState({
            isFetching: true
        });
        axios({
            method: 'post',
            url: this.apiurl+'/vehicle-usage/sendVerification',
            withCredentials: true,
            headers: {
                'Authorization': "Bearer "+this.state.accessTokenRequest,
                'Content-Type': 'application/json',
                'CSRF-Token': this.getCSRFToken(),
            },
            data: {
                requestId: this.state.requestId,
                method: "email",
                personId
            },
        })
            .then(res => {
                if(res.data.phase) {
                    this.setState({
                        logicError: null,
                        apiError: null,
                        currentPhase: res.data.phase,
                        isFetching: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    apiError: "API_REQUEST_FAILED",
                    isFetching: false
                });
            });
    }
    codeVerificationSubmit = () => {
        this.setState({
            isFetching: true
        });
        axios({
            method: 'post',
            url: this.apiurl+'/vehicle-usage/verifyToken',
            withCredentials: true,
            headers: {
                'Authorization': "Bearer "+this.state.accessTokenRequest,
                'Content-Type': 'application/json',
                'CSRF-Token': this.getCSRFToken(),
            },
            data: {
                requestId: this.state.requestId,
                code: this.state.verificationCode
            },
        })
            .then(res => {
                if(res.data.phase) {
                    const endDateForCalculation = addDays(parseISO(res.data.phaseData.EndDate),-3);
                    const calendarOpenWeek = endDateForCalculation<new Date() ? this.getFirstCalendarWeek() : this.getMonday(endDateForCalculation);
                    this.setState({
                        logicError: null,
                        apiError: null,
                        currentPhase: res.data.phase,
                        phaseData: res.data.phaseData,
                        isFetching: false,
                        lastCalendarWeek: addMonths(parseISO(res.data.phaseData.EndDate),2),
                        calendarOpenWeek,
                    },this.getServicePoints);
                }
                else if(res.data.invalidCode) {
                    this.setState({
                        apiError: null,
                        logicError: "ERROR.VERIFICATION_CODE_INVALID",
                        isFetching: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    apiError: "API_REQUEST_FAILED",
                    isFetching: false
                });
            });
    }
    cancelBooking = (bookingId) => {
        if(!window.confirm(this.t('CANCEL_BOOKING_CONFIRM'))) {
            return false;
        }
        this.setState({
            isFetching: true
        });
        axios({
            method: 'post',
            url: `${this.apiurl}/vehicle-usage/${bookingId}/cancel`,
            withCredentials: true,
            headers: {
                'Authorization': "Bearer "+this.state.accessTokenRequest,
                'Content-Type': 'application/json',
                'CSRF-Token': this.getCSRFToken(),
            },
            data: {
                requestId: this.state.requestId,
            }
        })
            .then(res => {
                if(res.data) {
                    this.setState({
                        isFetching: false,
                        phaseData: res.data.phaseData,
                    });
                }
            })
            .catch(err => {
                this.setState({
                    apiError: "API_REQUEST_FAILED",
                    isFetching: false
                });
            });
    }
    getServicePoints = () => {
        this.setState({
            isFetching: true
        });
        axios({
            method: 'get',
            url: this.apiurl+'/vehicle-usage/servicePoints',
            withCredentials: true,
            headers: {
                'Authorization': "Bearer "+this.state.accessTokenRequest,
                'Content-Type': 'application/json',
                'CSRF-Token': this.getCSRFToken(),
            }
        })
            .then(res => {
                if(res.data && res.data.servicePoints) {
                    this.setState({
                        isFetching: false,
                        apiError: null,
                        logicError: null,
                        servicePoints: res.data.servicePoints
                    });
                }
                else {
                    this.setState({
                        apiError: null,
                        logicError: "ERROR.NO_SERVICEPOINTS",
                        isFetching: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    apiError: "API_REQUEST_FAILED",
                    isFetching: false
                });
            });
    }
    getBookableTimes = (servicePointId,startDate) => {
        this.setState({
            isFetching: true
        });
        axios({
            method: 'get',
            url: this.apiurl+'/vehicle-usage/bookableTimes?requestId='+this.state.requestId+'&containerId='+servicePointId+'&startDate='+formatISO(startDate,{ representation: 'date'}),
            withCredentials: true,
            headers: {
                'Authorization': "Bearer "+this.state.accessTokenRequest,
                'Content-Type': 'application/json',
                'CSRF-Token': this.getCSRFToken(),
            }
        })
        .then(res => {
                if(res.data && res.data.bookableTimes) {
                    this.setState(prevState => {
                        var dayBookable = Object.assign({},(prevState.bookableTimes[res.data.servicePointId] || {}),res.data.bookableTimes);
                        return {
                            isFetching: false,
                            logicError: null,
                            apiError: null,
                            bookableTimes: update(prevState.bookableTimes,{$merge: {[res.data.servicePointId]: dayBookable}})
                        };
                    });
                }
            })
            .catch(err => {
                this.setState({
                    apiError: "API_REQUEST_FAILED",
                    isFetching: false
                });
            });
    }
    confirmBooking = () => {
        this.setState({
            isFetching: true
        });
        axios({
            method: 'post',
            url: this.apiurl+'/vehicle-usage/confirmBooking',
            withCredentials: true,
            headers: {
                'Authorization': "Bearer "+this.state.accessTokenRequest,
                'Content-Type': 'application/json',
                'CSRF-Token': this.getCSRFToken(),
            },
            data: {
                requestId: this.state.requestId,
                slot: this.state.selectedSlot,
                bookingComment: this.state.bookingComment,
                bookingPhoneNumber: this.state.bookingPhoneNumber,
                bookingName: this.state.bookingName,
                bookingEmail: this.state.bookingEmail,
                //
                servicePointName: this.state.servicePoint.name,
                servicePointAddress: this.state.servicePoint.street+', '+this.state.servicePoint.postalCode+' '+this.state.servicePoint.city,
            },
        })
            .then(res => {
                if(res.data.phase) {
                    this.setState({
                        logicError: null,
                        apiError: null,
                        currentPhase: res.data.phase,
                        phaseData: res.data.phaseData,
                        isFetching: false
                    });
                }
                else {
                    this.setState({
                        logicError: "ERROR.BOOKING_CONFIRM_FAILED",
                        apiError: null,
                        isFetching: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    apiError: "API_REQUEST_FAILED",
                    isFetching: false
                });
            });
    }
    licensePlateNumberChange = (event) => {
        const plate = event.target.value;
        this.setState({
            licensePlateNumber: plate
        }, () => {
            if(this.state.licensePlateNumberInvalidVisible || this.state.licensePlateNumber.length>4) {
                this.checkLicensePlateNumber();
            }
        });
    }
    bookingCommentChange = (event) => {
        const comment = event.target.value;
        this.setState({
            bookingComment: comment
        });
    }
    bookingPhoneNumberChange = (event) => {
        const phoneNumber = event.target.value;
        this.setState({
            bookingPhoneNumber: phoneNumber
        });
    }
    bookingEmailChange = (event) => {
        const email = event.target.value;
        this.setState({
            bookingEmail: email
        });
    }
    bookingNameChange = (event) => {
        const name = event.target.value;
        this.setState({
            bookingName: name
        });
    }
    checkLicensePlateNumber = () => {
        const valid = validateLicensePlateNumber(this.state.licensePlateNumber);
        this.setState({
            licensePlateNumberValid: valid,
            licensePlateNumberInvalidVisible: (this.state.licensePlateNumber && this.state.licensePlateNumber.length && !valid)
        });
    }
    verificationCodeChange = (event) => {
        const code = event.target.value;
        this.setState({
            verificationCode: code
        });
    }
    isConfirmable = () => {
        return (
            this.state.bookingName.length>3
            &&
            this.state.bookingPhoneNumber.length>5
            &&
            this.state.bookingEmail.length>7
        );
    }
    renderPersons = (phaseData) => {
        return (
            <div>
                {
                phaseData.persons.map((person,index) => (
                    <Row className="mt-2" key={index}>
                        <Col>
                            <FormLabel>
                                {person.personId === 'thirdPartyEmail' ? this.t('LABEL.THIRD_PARTY') : (index===0 ? this.t('LABEL.CAR_USER') : (index===1 ? this.t('LABEL.DECISION_MAKER') : (index===2 ? this.t('LABEL.THIRD_PARTY') : "")))}
                            </FormLabel>
                            <InputGroup>
                                <Form.Control
                                type="text"
                                value={person.emailObscured}
                                readOnly
                                />
                                <InputGroup.Append>
                                    <Button
                                    variant="primary"
                                    onClick={() => this.emailSubmit(person.personId)}
                                    >{this.t('BUTTON.SEND_VERIFICATION_EMAIL')}</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>
                ))
                }
            </div>
        );
    }
    renderContractFound = (phaseData) => {
        return (
            <>
                <Row className="my-3">
                    <Col>
                        <HtmlContent 
                        t={this.t}
                        ident="GUIDANCE.CONTRACT_FOUND"
                        /></Col>
                </Row>
                {
                    this.renderPersons(phaseData)
                }
            </>
        );
    }
    setServicePoint = (point) => {
        this.setState({
            servicePoint: point,
        });
        this.getBookableTimes(point.id,this.state.calendarOpenWeek);
    }
    selectSlot = (slot) => {
        this.setState({
            selectedSlot: slot
        });
    }
    showAllServicePoints = () => {
        this.setState({
            servicePoint: null,
            selectedSlot: null
        });
    }
    showCalendar = () => {
        this.setState({
            selectedSlot: null
        });
        this.getBookableTimes(this.state.servicePoint.id,this.state.calendarOpenWeek);
    }
    renderCodeVerification = (phaseData) => {
        return (
            <>
                <Row className="mt-3">
                    <Col><p>{this.t('GUIDANCE.CODE_VERIFICATION')}</p></Col>
                </Row>
                {
                    this.renderPersons(phaseData)
                }
                <Row className="mt-3">
                    <Col>
                    </Col>
                </Row>
                
                <Row className="mt-3">
                    <Col>
                        <FormLabel>{this.t('VERIFICATION_CODE')}</FormLabel>
                        <InputGroup>
                            <Form.Control
                            type="text"
                            size="lg"
                            placeholder="-----"
                            maxLength={8}
                            style={{
                                maxWidth: '300px'
                            }}
                            onChange={this.verificationCodeChange}
                            />
                            <InputGroup.Append>
                                <Button
                                variant="primary"
                                onClick={this.codeVerificationSubmit}  
                                disabled={this.state.verificationCode===""}
                                >{this.t('BUTTON.VERIFY_CODE')}</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
                {
                    (this.state.logicError) ?
                        (
                            <Alert className="mt-3" variant="warning">
                                {this.t('ERROR.VERIFICATION_CODE_INVALID')}
                            </Alert>
                        ) : ''
                }
            </>
        );
    }
    getFirstCalendarWeek = (date=new Date()) => {
        const currentDay = getISODay(date);
        if(currentDay>=5) return addDays(date,(7-currentDay)+1); // sunday -> next monday
        return this.getMonday(date);
    }
    getMonday = (date=new Date()) => {
        const currentDay = getISODay(date);
        return addDays(date,-currentDay+1);
    }
    mayCancelBooking = (booking) => {
        if(this.isBooker()) return true;
        if(new Date(booking.inspectionStart)>=addHours(new Date(),24)) {
            return true;
        }
        return false;
    }
    renderBookableTimes = (servicePointId,date) => {
        if(this.state.bookableTimes[servicePointId] && this.state.bookableTimes[servicePointId][date]) {
            return (
                this.state.bookableTimes[servicePointId][date].map((slot,index) => (
                    <Row key={index} className="calendar-slot-available">
                        <Col onClick={() => this.selectSlot(slot)}>{timeFormat(slot.Start)}</Col>
                    </Row>
                )
            ));
        }
        if(!this.state.isFetching)
            return (
                <Row><Col className="calendar-slot-none">{this.t('DAY_HAS_NO_SLOTS')}</Col></Row>
            )
    }
    navigateWeek = (direction) => {
        const newWeek = addWeeks(this.state.calendarOpenWeek,direction);
        this.setState({
            calendarOpenWeek: newWeek
        });
        this.getBookableTimes(this.state.servicePoint.id,newWeek);
    }
    hasExistingBookings = () => {
        return this.state.phaseData.existingBookings
            && this.state.phaseData.existingBookings.find(b => b.bookingCancelled == null);
    }
    renderServicePoints = () => {
        return (
            <div>
            {
                this.state.servicePoint ? '' : (
                    <Row>
                        <Col className="mt-3">
                            <p>{this.t('GUIDANCE.CHOOSE_SERVICEPOINT')}</p>
                        </Col>
                    </Row>
                )
            }

            <Row>
                <Col className="mt-3">
                    {
                        this.state.servicePoints.map((item,index) => (
                            (this.state.servicePoint===null || this.state.servicePoint.id===item.id) ? (
                                <Row key={index}>
                                    <Col sm="5" className="border-top">
                                        <strong>{item.name}</strong>
                                        <div>{item.street}</div>
                                        <div>{`${item.postalCode} ${item.city}`}</div>
                                    </Col>
                                    <Col sm="3" className="text-right border-top">
                                    {
                                        this.state.servicePoint!==null ? (
                                            <Button
                                             variant="link"
                                             onClick={this.showAllServicePoints}
                                             disabled={this.state.isFetching}
                                             >{this.t('BUTTON.SHOW_ALL_SERVICEPOINTS')}
                                            </Button>
                                        ) : (
                                            <Button
                                             variant="link"
                                             onClick={() => this.setServicePoint(item)}
                                             >{this.t('BUTTON.SELECT_SERVICEPOINT')}
                                            </Button>
                                        )
                                    }
                                    </Col>
                                </Row>
                            ) : ''
                        ))
                    }
                </Col>
            </Row>
            </div>
        )
    }
    renderCalendar = () => {
        const monday = this.state.calendarOpenWeek;
        const days = [0,1,2,3,4];
        return (
            <>
                <Row className="mb-2">
                    <Col xs="3" className="mt-3">
                        <Button
                            variant="secondary"
                            onClick={() => this.navigateWeek(-1)}
                            disabled={this.state.calendarOpenWeek<=this.state.firstCalendarWeek}
                            ><FontAwesomeIcon icon={faAngleDoubleLeft} />
                        </Button>
                    </Col>
                    <Col xs="6" className="mt-3 text-center">
                        {
                            [
                                this.t('WEEKNUMBER'),
                                ': ',
                                dfFormat(monday,'II/yyyy')
                            ]
                        }
                    </Col>
                    <Col xs="3" className="mt-3 text-right">
                        <Button
                            variant="secondary"
                            onClick={() => this.navigateWeek(1)}
                            disabled={this.state.lastCalendarWeek && this.state.calendarOpenWeek>=this.state.lastCalendarWeek}
                            ><FontAwesomeIcon icon={faAngleDoubleRight} />
                        </Button>
                    </Col>
                </Row>
                <Row className="flex-nowrap">
                    {
                        days.map((day,index) => {
                            const date = addDays(monday,day);
                            const datestr = formatISO(date,{
                                representation: 'date'
                            });
                            return (
                                <Col key={index} className="calendar-day-slots mt-3">
                                    <Row className="calendar-slot-title">
                                        <Col>{dateFormatShort(date,this.props.locale)}</Col>
                                    </Row>
                                    {
                                        this.renderBookableTimes(this.state.servicePoint.id,datestr)
                                    }
                                </Col>
                            );
                        })
                    }
                </Row>
            </>
        );
    }
    renderContract = (phaseData) => {
        return (
            <>
            <Row>
                <Col className="mt-3">
                    <div>
                        <strong>{this.t('VEHICLE')}: </strong>
                        {
                            [
                                phaseData.VehicleMake,
                                ' ',
                                phaseData.VehicleModel,
                                ' ',
                                phaseData.VehicleModelYear,
                                ' (', phaseData.licensePlateNumber + ')'
                            ]
                        }
                    </div>
                    <div>
                        <strong>{this.t('CONTRACT_TIME')}: </strong>
                        {[
                            dateFormat(phaseData.StartDate),
                            ' - ',
                            dateFormat(phaseData.EndDate)
                        ]}
                    </div>
                </Col>
            </Row>

            {
                this.state.phaseData.existingBookings ? (
                    <>
                        <Row>
                            <Col className="mt-4 mb-3">
                                <strong>{this.t('GUIDANCE.EXISTING_BOOKING')}</strong>
                            </Col>
                        </Row>
                        {
                            this.state.phaseData.existingBookings.map((booking,index) => (
                                <Row key={index}>
                                    <Col sm="5" className="border-top border-bottom py-2">
                                        {[dateFormat(booking.inspectionStart),' ',timeFormat(booking.inspectionStart)]}
                                        <br />
                                        {booking.inspectionContainerName}
                                    </Col>
                                    <Col sm="3" className="text-right border-top border-bottom pt-2">
                                        {
                                            booking.bookingCancelled ? (
                                                <div>{this.t('BOOKING_CANCELLED')}</div>
                                            ) : (
                                                this.mayCancelBooking(booking) ? (
                                                    <Button
                                                        variant="link"
                                                        onClick={() => this.cancelBooking(booking.id)}
                                                        >{this.t('CANCEL_BOOKING')}
                                                    </Button>
                                                ) : <small>{this.t('CANCEL_BOOKING_NOT_AVAILABLE')}</small>
                                            )
                                        }
                                    </Col>
                                </Row>
                            ))
                        }
                    </>

                ) : ""
            }

            {
                this.hasExistingBookings() ? '' : this.renderServicePoints()
            }
            {
                this.state.selectedSlot ? (
                    <>
                        <Row>
                            <Col sm="5" className="my-3">
                                <strong>{this.t('SELECTED_SLOT')}: </strong>
                                {
                                [
                                    dateFormatShort(this.state.selectedSlot.Start),
                                    ' ',
                                    timeFormat(this.state.selectedSlot.Start)
                                ]
                                }
                            </Col>
                            <Col sm="3" className="text-right">
                            {
                                !this.state.isFetching ? (
                                    <Button
                                    variant="link"
                                    onClick={this.showCalendar}
                                    >{this.t('BUTTON.SHOW_CALENDAR')}
                                    </Button>
                                ) : ''
                            }
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-1">
                                <FormLabel>{this.t('BOOKING_NAME')}</FormLabel>
                                <Form.Control
                                type="text"
                                maxLength={100}
                                onChange={this.bookingNameChange}
                                readOnly={this.state.isFetching} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-1">
                                <FormLabel>{this.t('BOOKING_PHONENUMBER')}</FormLabel>
                                <Form.Control
                                type="text"
                                maxLength={20}
                                onChange={this.bookingPhoneNumberChange}
                                readOnly={this.state.isFetching} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-1">
                                <FormLabel>{this.t('EMAIL')}</FormLabel>
                                <Form.Control
                                type="text"
                                maxLength={100}
                                onChange={this.bookingEmailChange}
                                readOnly={this.state.isFetching} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-1">
                                <FormLabel>{this.t('BOOKING_COMMENT')}</FormLabel>
                                <Form.Control
                                as="textarea"
                                onChange={this.bookingCommentChange}
                                readOnly={this.state.isFetching} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-1">
                                <Button
                                    variant="primary"
                                    onClick={this.confirmBooking}
                                    disabled={this.state.isFetching || !this.isConfirmable()}
                                    >{this.t('BUTTON.CONFIRM_BOOKING')}
                                </Button>
                            </Col>
                        </Row>
                        {
                            (this.state.logicError) ?
                                (
                                    <Row>
                                        <Col>
                                            <Alert className="mt-3" variant="warning">
                                                {this.t('ERROR.VERIFICATION_CODE_INVALID')}
                                            </Alert>
                                        </Col>
                                    </Row>
                                ) : ''
                        }
                    </>
                ) : (
                    this.state.servicePoint ? (
                        
                        <div>
                            <Row>
                                <Col className="my-3">
                                    <p>{this.t('GUIDANCE.CHOOSE_TIME')}</p>
                                </Col>
                            </Row>
                            {
                                this.renderCalendar()
                            }
                        </div>
                    ) : null
                )
            }
            </>
        );
    }
    renderBookingConfirmed = (phaseData) => {
        return (
            <>
            <Row>
                <Col>
                    <strong>{this.t('VEHICLE')}: </strong>
                    {[
                        phaseData.VehicleMake,
                        ' ',
                        phaseData.VehicleModel,
                        ' ',
                        phaseData.VehicleModelYear,
                        ' (', phaseData.licensePlateNumber + ')'
                    ]}
                </Col>
            </Row>
            <Row>
                <Col>
                    <strong>{this.t('SELECTED_SLOT')}: </strong>
                    {[
                        dateFormat(phaseData.inspectionStart),
                        ' ',
                        timeFormat(phaseData.inspectionStart),
                    ]}
                </Col>
            </Row>
            <Row>
                <Col>
                    <strong>{this.t('SELECTED_SERVICE_POINT')}: </strong>
                    {
                        this.state.servicePoints.map((item,index) => (
                            (item.id===phaseData.inspectionContainerId) ? (
                                <div key={index}>
                                    <div>{item.name}</div>
                                    <div>{item.street}</div>
                                    <div>{`${item.postalCode} ${item.city}`}</div>
                                </div>
                            ) : ''))
                    }
                </Col>
            </Row>
            {
                <Row>
                    <Col className="mt-3">
                        {this.t("GUIDANCE.CONFIRMED")}
                    </Col>
                </Row>
            }
            </>
        );
    }
    renderReturnGuidance = () => {
        return (
            <Row className="mt-4">
                <Col 
                  className="border p-3"
                  style={{
                    fontSize: "80%"
                }}><HtmlContent 
                    t={this.t}
                    ident="GUIDANCE.RETURN"
                    />
                    <p>
                        <a target="_blank" href={this.t('VEHICLE_RETURN_GUIDE.URL')} rel="noreferrer">
                            {this.t('VEHICLE_RETURN_GUIDE.TEXT')}
                            {' '}
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>
                    </p>
                </Col>
            </Row>
        );
    }
    renderPhaseData = () => {
        if(this.state.currentPhase === "CONTRACT_FOUND") {
            return this.renderContractFound(this.state.phaseData);
        }
        else if(this.state.currentPhase === "VERIFICATION_CODE_SENT") {
            return this.renderCodeVerification(this.state.phaseData);
        }
        else if(this.state.currentPhase === "VERIFICATION_SUCCESSFUL") {
            return this.renderContract(this.state.phaseData);
        }
        else if(this.state.currentPhase === "BOOKING_CONFIRMED") {
            return this.renderBookingConfirmed(this.state.phaseData);
        }
    }
    render() {
        return (
            <>
                {
                    this.state.initialized ? '' : <LoadingFullScreen />
                }
                <Container>
                    {
                        this.state.iFrameMode ? '' : (
                            <Row>
                                <Col className="mb-3">
                                    <img src="./secto_280x105.png" className="header-secto-logo" alt="secto" />
                                </Col>
                            </Row>
                        )
                    }
                    <Row>
                        <Col>
                            {
                                ["START","CONTRACT_FOUND","VERIFICATION_CODE_SENT"].indexOf(this.state.currentPhase)>-1 ? (
                                    <Form onSubmit={(ev) => { ev.preventDefault(); this.licensePlateNumberSubmit(); }}>
                                        <Row>
                                            <Col><p>{this.t('GUIDANCE.START')}</p></Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <FormLabel>{this.t('LICENSEPLATE')}</FormLabel>
                                                <InputGroup>
                                                    <Form.Control
                                                    type="text"
                                                    size="lg"
                                                    placeholder="ABC-123"
                                                    maxLength={8}
                                                    onChange={this.licensePlateNumberChange}
                                                    value={this.state.licensePlateNumber}
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        maxWidth: '300px'
                                                    }}
                                                    onBlur={this.checkLicensePlateNumber}
                                                    isValid={this.state.licensePlateNumberValid}
                                                    disabled={this.state.currentPhase!=='START'}
                                                    />
                                                    {
                                                        this.state.currentPhase==='START' ? (
                                                            <InputGroup.Append>
                                                                <Button
                                                                type="submit"
                                                                variant="primary"
                                                                disabled={!this.state.licensePlateNumberValid}
                                                                >{this.t('BUTTON.BEGIN')}</Button>
                                                            </InputGroup.Append>
                                                        ) : ''
                                                    }
                                                </InputGroup>
                                                {
                                                    (this.state.licensePlateNumberInvalidVisible) ?
                                                        (
                                                            <div className="text-danger">
                                                                {this.t('LICENSEPLATE.INVALID')}
                                                            </div>
                                                        ) : ''
                                                }
                                                {
                                                    (this.state.apiError) ?
                                                        (
                                                            <div className="text-danger">
                                                                {this.t(this.state.apiError)}
                                                            </div>
                                                        ) : ''
                                                }
                                                {
                                                    (this.state.logicError && this.state.currentPhase==='START') ?
                                                        (
                                                            <Alert className="mt-3" variant="warning">
                                                                {this.t('ERROR.CONTRACT_NOT_FOUND')}
                                                                <div>&nbsp;</div>
                                                                <div>
                                                                    {this.t('CUSTOMER_SERVICE')}: 
                                                                    <div><a href={`mailto:${this.t('CUSTOMER_SERVICE_EMAIL')}`}>{this.t('CUSTOMER_SERVICE_EMAIL')}</a></div>
                                                                    <div>{this.t('TEL')} <a href={`tel:${this.t('CUSTOMER_SERVICE_PHONE_LINK')}`}>{this.t('CUSTOMER_SERVICE_PHONE')}</a></div>

                                                                </div>
                                                            </Alert>
                                                        ) : ''
                                                }
                                            </Col>
                                        </Row>
                                    </Form>
                                ) : ''
                            }
                            {
                                this.renderPhaseData()
                            }
                            {
                                this.state.isFetching ? (
                                    <Row className="mt-4">
                                        <Col>
                                            <IsFetchingSpinner />
                                        </Col>
                                    </Row>
                                ) : ''
                            }
                            {
                                ['VERIFICATION_SUCCESSFUL','BOOKING_CONFIRMED'].indexOf(this.state.currentPhase)>-1 ? 
                                  this.renderReturnGuidance()
                                  : ''
                            }
                            {
                                this.state.currentPhase!=='START' ? (
                                    <Row className="mt-5 text-right">
                                        <Col>
                                            <Button 
                                            variant="link"
                                            onClick={this.resetSession}>{this.t('BUTTON.RESET')}</Button>
                                        </Col>
                                    </Row>
                                ) : ''
                            }
                            <Row>
                                <Col className="mt-4 text-right">
                                    <AuthenticatedTemplate>
                                        <Button
                                            variant="link"
                                            onClick={this.logout}
                                        >{this.t('BOOKER.LOGOUT')}</Button>
                                    </AuthenticatedTemplate>
                                    <UnauthenticatedTemplate>
                                        {
                                            this.state.sectoAuth ? <small>Kirjautunut / Secto</small> : (
                                                <Button
                                                    variant="link"
                                                    onClick={this.login}
                                                >{this.t('BOOKER.LOGIN')}</Button>
                                            )
                                        }
                                    </UnauthenticatedTemplate>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

const StartPageWithMsal = withMsal(StartPageComponent);

export default class StartPage extends React.Component {
    render() {
        return (
            <MsalProvider instance={msalInstance}>
                <StartPageWithMsal { ...this.props } />
            </MsalProvider>
        )
    }
}