import i18n from "i18next";
import { initReactI18next } from "react-i18next";


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
        fi: {
            translation: require('./translations/fi.json')
        },
    },
    lng: "fi",
    nsSeparator: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

  export default i18n;